<template>
  <v-container>
    <v-row>
      <v-col lg="6">
        <div class="mb-10">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            ЭЕШ сорилын өсөлт, амжилтын дүн шинжилгээ
            <span v-if="ownAssignments"
              >(Нийт сорилын тоо: {{ ownAssignments.length }})</span
            >
          </h5>
          <p class="text-sm text-body mb-0">
            Энэxүү xуудас нь ЭЕШ сорилын өсөлт, амжилтын дүн шинжилгээг
            сургууль, судлагдаxуун бүрээр нь xаруулна.
          </p>
        </div>
      </v-col>
    </v-row>
    <div v-if="loading == false">
      <v-row v-if="categories">
        <v-card
          :style="
            sindex == 0
              ? 'background-color:#ffc300'
              : sindex == 1
              ? 'background-color:#c0c0c0'
              : sindex == 2
              ? 'background-color:#e58729'
              : ''
          "
          class="ma-3"
          v-for="(school, sindex) in getLeaderBoards"
          :key="'leaderboard' + sindex"
        >
          <v-card-title> {{ sindex + 1 }}. {{ school.name }} </v-card-title>
          <v-card-text
            class="mt-n3"
            style="font-size: 24px; color: black; font-weight: bold"
          >
            {{ (school.average / school.sorilLength).toFixed(2) }}
          </v-card-text>
        </v-card>
      </v-row>
      <v-card-text class="px-0 py-0 scoreTables mt-4">
        <v-card
          v-for="(category, lindex) in categories"
          :key="'categories' + lindex"
          class="mt-10"
        >
          <v-row
            class="ma-0"
            justify="space-between
      "
          >
            <h2 class="pa-1">
              {{ category.name }}
              <v-btn
                @click="_cal(category)"
                small
                elevation="0"
                class="pa-0 px-1"
                color="red"
                dark
                height="20"
              >
                <small> <v-icon small>mdi-refresh</v-icon> update</small></v-btn
              >
            </h2>
            <v-btn icon @click="category.viewMode = !category.viewMode">
              <v-icon v-if="category.viewMode">mdi-table</v-icon>
              <v-icon v-else>mdi-chart-line</v-icon>
            </v-btn>
          </v-row>
          <div v-if="category.viewMode">
            <v-data-table
              :headers="category.sorils"
              :items="category.schools"
              hide-default-footer
              item-key="name"
              sort-by="average"
              :sort-desc="true"
              :items-per-page="-1"
              v-if="category.sorils && category.schools"
            >
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>

              <template
                v-slot:[getSlotName(hindex)]="{ item }"
                v-for="(header, hindex) in category.sorils.slice(
                  2,
                  category.sorils.length - 1
                )"
              >
                <td :key="'headerr2' + hindex">
                  {{ item.sorils[hindex].totalAverage }}

                  <v-btn
                    class="px-0"
                    small
                    :ripple="false"
                    v-if="hindex != 0"
                    text
                    :style="
                      item.sorils[hindex].totalAverage -
                        item.sorils[hindex - 1].totalAverage >
                      0
                        ? 'color: green'
                        : item.sorils[hindex].totalAverage -
                            item.sorils[hindex - 1].totalAverage <
                          0
                        ? 'color: red'
                        : ''
                    "
                  >
                    <v-icon
                      :class="
                        item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''
                      "
                      v-if="
                        item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage >
                        0
                      "
                      >mdi-arrow-top-right</v-icon
                    >
                    <v-icon
                      :class="
                        item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''
                      "
                      v-else-if="
                        item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage <
                        0
                      "
                      >mdi-arrow-bottom-right</v-icon
                    >
                    <v-icon
                      v-else-if="
                        item.sorils[hindex].totalAverage != '-' &&
                        item.sorils[hindex].totalAverage ==
                          item.sorils[hindex - 1].totalAverage
                      "
                      :class="
                        item.sorils[hindex].totalAverage < 10 ? 'ml-2' : ''
                      "
                      >mdi-minus</v-icon
                    >
                    <span
                      v-if="
                        item.sorils[hindex].totalAverage != '-' &&
                        item.sorils[hindex - 1].totalAverage != '-'
                      "
                    >
                      {{
                        (
                          item.sorils[hindex].totalAverage -
                          item.sorils[hindex - 1].totalAverage
                        ).toFixed(2)
                      }}
                    </span>
                  </v-btn>
                </td>
              </template>
              <template v-slot:body.append="{ headers }">
                <tr style="background-color: #b4b4b4">
                  <td>{{ category.schools.length + 1 }}</td>
                  <td>Бүх Сургуулиуд</td>
                  <td
                    v-for="(header, hindex) in headers.slice(2)"
                    :key="'headerr' + hindex"
                  >
                    {{ getAverages(header, hindex, category) }}

                    <v-btn
                      class="px-0"
                      small
                      :ripple="false"
                      v-if="hindex != 0 && header.value != 'average'"
                      text
                      :style="
                        getAverages(header, hindex, category) -
                          getAverages(header, hindex - 1, category) >
                        0
                          ? 'color: green'
                          : getAverages(header, hindex, category) -
                              getAverages(header, hindex - 1, category) <
                            0
                          ? 'color: red'
                          : ''
                      "
                    >
                      <v-icon
                        :class="
                          getAverages(header, hindex, category) < 10
                            ? 'ml-2'
                            : ''
                        "
                        v-if="
                          getAverages(header, hindex, category) -
                            getAverages(header, hindex - 1, category) >
                          0
                        "
                        >mdi-arrow-top-right</v-icon
                      >
                      <v-icon
                        :class="
                          getAverages(header, hindex, category) < 10
                            ? 'ml-2'
                            : ''
                        "
                        v-else-if="
                          getAverages(header, hindex, category) -
                            getAverages(header, hindex - 1, category) <
                          0
                        "
                        >mdi-arrow-bottom-right</v-icon
                      >
                      <v-icon
                        v-else
                        :class="
                          getAverages(header, hindex, category) < 10
                            ? 'ml-2'
                            : ''
                        "
                        >mdi-minus</v-icon
                      >
                      {{
                        (
                          getAverages(header, hindex, category) -
                          getAverages(header, hindex - 1, category)
                        ).toFixed(2)
                      }}
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <!-- <v-sparkline :value="value" type="trend" auto-draw :labels="labels">
          </v-sparkline> -->
            chart here
          </div>
        </v-card>
      </v-card-text>
    </div>
    <div v-else class="d-flex flex-row justify-center">
      <v-progress-circular
        class="mt-16"
        :size="70"
        :width="7"
        color="red"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
// import { store } from "@/store.js";
import { sync } from "vuex-pathify";
import moment from "moment";
export default {
  name: "light-table-transparent",
  data() {
    return {
      loading: true,
      schools: null,
      actionstatus: null,
      search: "",
      snackbar: false,
      students: null,
      categories: null,
      ownAssignments: null,
      total: 0,
    };
  },

  computed: {
    ...sync("*"),

    getLeaderBoards() {
      var list = [];
      if (this.categories)
        this.categories.forEach((category) => {
          if (category.schools)
            category.schools.forEach((school) => {
              var found = list.map((e) => e.id).indexOf(school.id);
              if (found != -1) {
                list[found].average =
                  list[found].average + Number(school.average);
                if (school.average != "-" && school.average != "0.00")
                  list[found].sorilLength++;
              } else {
                list.push({
                  average:
                    Number(school.average) != "-" ? Number(school.average) : 0,
                  name: school.name,
                  id: school.id,
                  sorilLength: Number(school.average) != "-" ? 1 : 0,
                });
              }
            });
        });
      list.sort(
        (a, b) => b.average / b.sorilLength - a.average / a.sorilLength
      );
      return list.slice(0, 10);
    },
    getAverages() {
      return (header, hindex, category) => {
        var totalAverage = 0;
        if (header.value == "average") {
          var counter = 0;
          category.schools.forEach((school) => {
            if (school.average != "-" && school.average != "0.00") {
              totalAverage = totalAverage + Number(school.average);
              counter++;
            }
          });
          totalAverage = totalAverage / counter;
        } else {
          var counter2 = 0;
          category.schools.forEach((school) => {
            if (
              school.sorils[hindex] &&
              school.sorils[hindex].totalAverage != "0.00" &&
              school.sorils[hindex].totalAverage != "-"
            ) {
              totalAverage =
                totalAverage + Number(school.sorils[hindex].totalAverage);
              counter2++;
            }
          });

          totalAverage = totalAverage / counter2;
        }
        if (isNaN(totalAverage)) totalAverage = 0;
        return totalAverage.toFixed(2);
      };
    },
  },

  methods: {
    _cal(category) {
      var sorils = category.sorils;
      if (sorils && sorils.length > 0) {
        category.schools.forEach((school) => {
          sorils.forEach((assign) => {
            this._calAverage(assign, school);
          });
        });
      }
    },
    async _calAverage(assignment, school) {
      console.log(assignment);
      var resultData = {};
      var xx = await assignment.categoryRef
        .collection("lesson-students")
        .where("ppschool", "==", school.ref.path)
        .get();

      var query = assignment.ref
        .collection("results-simplified")
        .orderBy("totalScore", "desc");

      query = query.where("schoolRef", "==", school.ref);
      query.onSnapshot(async (docs) => {
        this.results = [];
        if (!docs.empty) {
          var sumOfAverageTotalScores = 0;
          docs.forEach((doc) => {
            let result = doc.data();
            result.ref = doc.ref;
            result.id = doc.id;
            sumOfAverageTotalScores =
              sumOfAverageTotalScores + result.totalScore;
            this.results.push(result);
          });
          assignment.selectedLessonCategory = null;
          resultData = {
            totalAverage: sumOfAverageTotalScores / this.results.length,
            numberOfParticipants: this.results.length,
            assignmentId: assignment.id,
            assignmentName: assignment.name,
            assignment: assignment,
            assignmentRefPath: assignment.ref.path,
            schoolId: school.id,
            schoolName: school.name,
            schoolRefPath: school.ref.path,
            // school: school,
            calculatedAt: new Date(),
            numberOfInterested: xx.size,
          };
          school.ref
            .collection("soril-confirmed-results")
            .doc(assignment.id)
            .set(resultData, { merge: true });
        } else {
          assignment.selectedLessonCategory = null;
          resultData = {
            totalAverage: 0,
            numberOfParticipants: 0,
            assignmentId: assignment.id,
            assignmentName: assignment.name,
            assignment: assignment,
            assignmentRefPath: assignment.ref.path,
            schoolId: school.id,
            schoolName: school.name,
            schoolRefPath: school.ref.path,
            // school: school,
            calculatedAt: new Date(),
            numberOfInterested: xx.size,
          };
          // this.assignment.ref
          //   .collection("schools-confirmed-report")
          //   .doc(this.userData.school.id)
          //   .collection("assignments")
          //   .doc(this.assignment.id)
          //   .set(resultData, { merge: true });
          school.ref
            .collection("soril-confirmed-results")
            .doc(assignment.id)
            .set(resultData, { merge: true });
        }
      });
    },
    getSlotName(hindex) {
      return "item.sorils[" + hindex + "].totalAverage";
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY/MM/DD");
      }
      return "-";
    },
  },
  created() {
    fb.db.collection("categories").onSnapshot((categories) => {
      this.categories = [];
      categories.forEach(async (doc) => {
        let eyesh = doc.data();
        eyesh.ref = doc.ref;
        eyesh.id = doc.id;
        eyesh.viewMode = true;
        eyesh.sorils = null;

        await fb.db
          .collection("assignmentsdatabase")
          .where("categoryRef", "==", eyesh.ref)
          .where("createdByEmail", "==", "granditxxk@gmail.com")
          .where("deleted", "==", false)
          .orderBy("createdAt", "asc")
          .onSnapshot((docs) => {
            eyesh.sorils = [
              {
                text: "No",
                align: "start",
                value: "index",
                sortable: false,
              },
              { text: "Сургуулийн нэр", value: "name" },
            ];
            var counter = 0;
            docs.forEach((doc) => {
              let assign = doc.data();
              assign.id = doc.id;
              assign.ref = doc.ref;
              assign.text =
                assign.name +
                " " +
                assign.createdYear +
                "/" +
                assign.createdMonth;
              assign.value = "sorils[" + counter + "].totalAverage";

              eyesh.sorils.push(assign);
              counter++;
            });
            eyesh.sorils.push({ text: "Дундаж", value: "average" });
          });

        eyesh.schools = null;
        fb.db
          .collection("schools")
          .where("schoolIndex", "==", this.userData.schoolIndex)
          .get()
          .then((categories) => {
            eyesh.schools = [];
            var counter = 1;
            categories.forEach((lesson) => {
              let school = lesson.data();
              school.ref = lesson.ref;
              school.id = lesson.id;
              school.sorils = [];
              school.index = counter;
              school.average = 0;

              var sorilCounter = 1;
              let sorils = eyesh.sorils.filter(
                (soril) => soril.categoryRef != undefined
              );

              var counter2 = 0;
              sorils.forEach((soril) => {
                school.ref
                  .collection("soril-confirmed-results")
                  .doc(soril.id)
                  .onSnapshot((doc) => {
                    if (doc.exists) {
                      let result = doc.data();
                      result.id = doc.id;
                      result.ref = doc.ref;
                      school.average = school.average + result.totalAverage;
                      console.log(school.average);
                      if (result.totalAverage > 0) counter2++;
                      if (sorils.length == sorilCounter) {
                        school.average = (school.average / counter2).toFixed(2);
                        console.log(counter2);
                        setTimeout(() => {
                          this.loading = false;
                        }, 3000);
                      } else {
                        this.loading = true;
                      }
                      if (isNaN(school.average)) school.average = "0.00";
                      school.sorils.push({
                        schoolName: school.name,
                        assignmentId: result.assignmentId,
                        assignmentName: result.assignmentName,
                        totalAverage: result.totalAverage.toFixed(2),
                      });
                      sorilCounter++;
                    } else {
                      if (sorils.length == sorilCounter) {
                        school.average = (school.average / counter2).toFixed(2);
                        setTimeout(() => {
                          this.loading = false;
                        }, 3000);
                      } else {
                        this.loading = true;
                      }
                      if (isNaN(school.average)) school.average = "0.00";
                      school.sorils.push({
                        schoolName: school.name,
                        assignmentId: soril.id,
                        assignmentName: soril.name,
                        totalAverage: "-",
                      });
                      sorilCounter++;
                    }
                  });
              });

              eyesh.schools.push(school);
              counter++;
            });
          });

        this.categories.push(eyesh);
      });
      console.log(this.categories);
    });
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
